@at-root {

  @-ms-viewport {
    width: device-width;
  }
}

 @font-face {
   font-family: 'Open Sans';
   src: url('../fonts/open-sans-regular.woff2') format('woff2'),
	 url('../fonts/open-sans-regular.woff') format('woff');
   font-weight: 400;
   font-style: normal;
 }

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/open-sans-700.woff2') format('woff2'),
	url('../fonts/open-sans-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../fonts/merriweather-regular.woff2') format('woff2'),
	url('../fonts/merriweather-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: url('../fonts/merriweather-700.woff2') format('woff2'),
	url('../fonts/merriweather-700.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__content {
    flex-grow: 1;
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  text-align: left;
  color: $text-color;
  background-color: $body-bg;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
	font-family: $font-family;
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
	vertical-align: top;
}

a {
  color: $link-color;
  background-color: transparent;
	text-decoration: none;
  transition-property: color, border-color;
	transition-duration: $transition-time;

  &:hover,
  &:active {
    color: $link-color--hover;
  }

  // &:visited {
  //   color: $link-color--hover;
  // }

  &[href^="tel:"] {
		white-space: nowrap;

		@media (min-width: $screen-lg) {
			pointer-events: none;
		}
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: $typo-margin-vertical * 2;
  margin-bottom: $typo-margin-vertical * 2;
  border: 0;
  border-top: 1px solid $border-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: $font-family;
  font-weight: 400;
  line-height: 1.3;
  color: currentColor;
}

h1,
h2 {
	font-family: $font-family--secondary;
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: $typo-margin-vertical;
}

blockquote {
	font-style: italic;
}

p {
	margin-bottom: 16px;
	font-size: 14px;
	line-height: 18px;

	@media (min-width: $screen-sm) {
		font-size: 16px;
		line-height: 22px;
	}
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: $typo-margin-vertical;
  padding-left: 1.5em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

ul:not([class]) {
	margin: 0 0 16px;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		margin-bottom: 16px;
		padding-left: 24px;
		font-size: 16px;
		line-height: 26px;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 28px;
			text-align: justify;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: "";
			position: absolute;
			top: 5px;
			left: 0;
			width: 8px;
			height: 8px;
			background-color: $color-secondary;

			@media (min-width: $screen-sm) {
				top: 7px;
			}
		}
	}
}

ol:not([class]) {
	margin: 0 0 16px;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 26px;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 28px;
			text-align: justify;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

li {
  line-height: inherit;
}

small {
  font-size: $font-size-sm;
}

sub,
sup {
  font-size: $font-size-sm;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre {
  font-family: $font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: rgba(#000, 0.08);
  border-radius: $border-radius;
}

kbd {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: $gray-lightest;
  border-radius: $border-radius;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  width: 100%;
  margin-bottom: $typo-margin-vertical;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);

  code {
    background: transparent;
  }
}

