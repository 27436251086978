.swiper-pagination-bullet {
	position: relative;
	background-color: $white;
	opacity: 0.25;
	transition: opacity $transition-time;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 24px;
		height: 24px;
		border: 1px solid transparent;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transition: border-color $transition-time;
	}
}

.swiper-pagination-bullet-active {
	opacity: 1;

	&::after {
		border-color: $white;
	}
}

.swiper-container-horizontal > .swiper-pagination-bullets {
	bottom: 24px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets {
	left: 40px;
	right: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 16px 0;
}
