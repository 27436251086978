h1,
h2 {
	margin-top: 0;
	margin-bottom: 16px;
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	text-transform: uppercase;

	@media (min-width: $screen-sm) {
		margin-bottom: 24px;
		font-size: 44px;
		line-height: 53px;
	}

	@media (min-width: $screen-xl) {
		font-size: 54px;
		line-height: 65px;
	}
}

h1 {

	@media (min-width: $screen-xl) {
		margin-bottom: 8px;
		font-size: 72px;
		line-height: 86px;
		color: $color-secondary;
	}
}

h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 16px;
	font-family: $font-family;
	font-size: 18px;
	line-height: 24px;

	@media (min-width: $screen-sm) {
		margin-bottom: 24px;
		font-size: 20px;
		line-height: 27px;
	}
}

h3 {

	@media (min-width: $screen-xl) {
		margin-bottom: 32px;
	}
}

p {
	font-size: 16px;
	line-height: 26px;

	@media (min-width: $screen-sm) {
		text-align: justify;
		font-size: 18px;
		line-height: 28px;
	}
}
