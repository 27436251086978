.btn {
	$self: &;
	display: inline-block;
	min-width: 200px;
	max-width: 100%;
	margin: 0;
	padding: 10px 8px;
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	color: $color-secondary;
	background-color: transparent;
	border: 2px solid $color-secondary;
	border-radius: 0;
	vertical-align: middle;
	overflow: hidden;
	transition: color, background-color, border-color;
	transition-duration: $transition-time;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: rgba($color-secondary, 0.25);
	}

	&:active {
		background-color: rgba($color-secondary, 0.25);
	}

	&--size_large {
		padding: 26px 16px;
		font-size: 24px;
	}
}

.btn-close {
	$self: &;
	position: relative;
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	border-radius: 0;
	transition: background-color $transition-time;
	cursor: pointer;

	&:hover,
	&:active {

		&::before,
		&::after {
			background-color: rgba($color-secondary, 0.7);
		}
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 32px;
		height: 2px;
		background-color: $color-secondary;
		transition: background-color $transition-time;
	}

	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

