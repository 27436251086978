.main-title {
	color: $color-secondary;
}

.main-subtitle {
	margin-top: 0;
	margin-bottom: 24px;
	font-size: 20px;
	line-height: 27px;
	text-transform: none;
	color: $color-secondary;

	@media (min-width: $screen-sm) {
		margin-bottom: 32px;
		font-size: 28px;
		line-height: 34px;
	}

	@media (min-width: $screen-xl) {
		font-size: 36px;
		line-height: 43px;
	}
}
