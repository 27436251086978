.contact-form {
	$self: &;

	&__col {
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}

		@media (min-width: $screen-sm) {
			margin-bottom: 32px;
		}
	}

	&__input,
	&__textarea {
		width: 100%;
	}

	&__submit {
		width: 100%;
		text-align: center;
	}

	&__btn {
		width: 100%;
	}

	&__agreement {

		:last-child {
			margin-bottom: 0;
		}
	}
}

.contact-form-success {
	display: none;
	text-align: center;

	p {
		@media (min-width: $screen-sm) {
			font-size: 24px;
			line-height: 32px;
		}
	}
}
