.blockquote {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	margin-top: 0;
	margin-bottom: 16px;
	font-family: $font-family;
	font-size: 16px;
	line-height: 22px;
	text-align: justify;

	@media (min-width: $screen-sm) {
		margin-bottom: 24px;
		font-size: 18px;
		line-height: 24px;
	}

	p {
		max-width: 60ch;
		font-size: 16px;
		line-height: 22px;

		@media (min-width: $screen-sm) {
			font-size: 18px;
			line-height: 24px;
			text-align: justify;
		}
	}
}
