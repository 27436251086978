.page-footer {
	display: none;
	position: fixed;
	bottom: 24px;
	left: 0;
	z-index: 1;
	width: 100%;

	@media (min-width: $screen-md) {
		display: block;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__copyright {
		font-size: 10px;
		line-height: 14px;
		color: rgba($white, 0.5);

		@media (min-width: $screen-xl) {
			font-size: 12px;
		}
	}
}
