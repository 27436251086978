.main-slider {
	width: 100%;
	height: 100%;

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;
		width: 100%;
		min-height: 100vh;
		height: auto;
		background: none !important;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding-top: 152px;
		padding-bottom: 72px;
		box-sizing: border-box;
		overflow: hidden;

		@media (min-width: $screen-md) {
			height: 100%;
			justify-content: center;
			padding: 0;
		}
	}

	&__bg-wrap {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__img-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		opacity: 0.1;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__header {
		text-align: center;
	}

	&__content {
		z-index: 1;
		width: 100%;
		padding-top: 24px;

		@media (min-width: $screen-sm) {
			padding-bottom: 24px;
		}
		//padding: 72px 0;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
		@include col(md, 10, 12);
		@include col-offset(md, 1, 12);
		@include col(lg, 8, 12);
		@include col-offset(lg, 2, 12);
	}
}

.animate-bg {


}

.animate {

}


@keyframes animateBg {

	from {
		transform: scale(1, 1) translateZ(1px);
	}

	to {
		transform: scale(1.45, 1.45) translateZ(1px);
	}
}
