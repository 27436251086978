.page-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;

	@media (min-width: $screen-md) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		padding-top: 24px;
		padding-bottom: 24px;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__logo {
		@include col(xs, 5, 12);
		@include col(md, 2, 12);
		position: relative;
	}

	&__logo-wrap {
		display: block;
		height: 128px;

		@media (min-width: $screen-md) {
			position: absolute;
			top: 0;
			left: 12px;
		}

		@media (min-width: $screen-lg) {
			height: 192px;
		}

		@media (min-width: $screen-xxl) {
			height: 240px;
		}
	}

	&__logo-img {
		height: 100%;
		vertical-align: top;
	}

	&__service {
		@include col(xs, 7, 12);
		@include col(md, 10, 12);
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: flex-end;

		@media (min-width: $screen-md) {
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
		}
	}

	&__btn-wrap {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			margin-right: 64px;
		}
	}

	&__link-wrap {

		@media (min-width: $screen-md) {
			margin-right: 64px;
		}
	}

	&__link {
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		color: rgba($white, 0.5);
	}

	&__menu-toggle {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 24px;
		font-size: 0;

		@media (min-width: $screen-md) {
			margin-bottom: 0;
		}
	}

	&__menu-label {
		margin-right: 24px;
		font-size: 18px;
		line-height: 25px;
		color: $color-secondary;

		@media (min-width: $screen-md) {
			z-index: 1;
		}
	}
}
