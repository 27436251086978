/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger.is-active:hover {
  opacity: 1;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #D2B677;
}

.hamburger-box {
  width: 64px;
  height: 22px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 64px;
  height: 2px;
  background-color: #D2B677;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 128px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 128px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 128px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 128px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 128px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 128px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-12.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-12.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(12.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(12.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-12.8px, -16px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-12.8px, 16px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(12.8px, -16px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(12.8px, 16px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -128px;
  top: -128px;
  transform: translate3d(128px, 128px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -128px;
  top: -128px;
  transform: translate3d(-128px, 128px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -128px;
  top: 128px;
  transform: translate3d(128px, -128px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -128px;
  top: 128px;
  transform: translate3d(-128px, -128px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1px;
}

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-9.14286px, -8px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(9.14286px, -8px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

@-ms-viewport {
  width: device-width;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans-regular.woff2") format("woff2"), url("../fonts/open-sans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans-700.woff2") format("woff2"), url("../fonts/open-sans-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url("../fonts/merriweather-regular.woff2") format("woff2"), url("../fonts/merriweather-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url("../fonts/merriweather-700.woff2") format("woff2"), url("../fonts/merriweather-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.page {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;
}

.page__inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__content {
  flex-grow: 1;
}

.page__footer-wrapper {
  flex-shrink: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: white;
  background-color: #621c22;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;
}

a svg,
button svg {
  pointer-events: none;
}

svg:not(:root) {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a {
  color: #D2B677;
  background-color: transparent;
  text-decoration: none;
  transition-property: color, border-color;
  transition-duration: 0.3s;
}

a:hover, a:active {
  color: #be973f;
}

a[href^="tel:"] {
  white-space: nowrap;
}

@media (min-width: 1280px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #b3b3b3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.3;
  color: currentColor;
}

h1,
h2 {
  font-family: "Merriweather", "Times New Roman", serif;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 1em;
}

blockquote {
  font-style: italic;
}

p {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 768px) {
  p {
    font-size: 16px;
    line-height: 22px;
  }
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: 1em;
  padding-left: 1.5em;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-top: 0;
  margin-bottom: 0;
}

ul:not([class]) {
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
}

ul:not([class]) li {
  position: relative;
  margin-bottom: 16px;
  padding-left: 24px;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  ul:not([class]) li {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
  }
}

ul:not([class]) li:last-child {
  margin-bottom: 0;
}

ul:not([class]) li::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #D2B677;
}

@media (min-width: 768px) {
  ul:not([class]) li::before {
    top: 7px;
  }
}

ol:not([class]) {
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
}

ol:not([class]) li {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  ol:not([class]) li {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
  }
}

ol:not([class]) li:last-child {
  margin-bottom: 0;
}

li {
  line-height: inherit;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  font-size: 0.75em;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

code {
  padding: 0.06em 0.3em;
  color: white;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

kbd {
  padding: 0.06em 0.3em;
  color: white;
  background-color: #e6e6e6;
  border-radius: 3px;
}

kbd kbd {
  padding: 0;
}

pre {
  display: block;
  width: 100%;
  margin-bottom: 1em;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08);
}

pre code {
  background: transparent;
}

.visuallyhidden {
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.btn {
  display: inline-block;
  min-width: 200px;
  max-width: 100%;
  margin: 0;
  padding: 10px 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: #D2B677;
  background-color: transparent;
  border: 2px solid #D2B677;
  border-radius: 0;
  vertical-align: middle;
  overflow: hidden;
  transition: color, background-color, border-color;
  transition-duration: 0.3s;
  user-select: none;
  cursor: pointer;
}

.btn:hover {
  background-color: rgba(210, 182, 119, 0.25);
}

.btn:active {
  background-color: rgba(210, 182, 119, 0.25);
}

.btn--size_large {
  padding: 26px 16px;
  font-size: 24px;
}

.btn-close {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  transition: background-color 0.3s;
  cursor: pointer;
}

.btn-close:hover::before, .btn-close:hover::after, .btn-close:active::before, .btn-close:active::after {
  background-color: rgba(210, 182, 119, 0.7);
}

.btn-close::before, .btn-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 2px;
  background-color: #D2B677;
  transition: background-color 0.3s;
}

.btn-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.btn-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
}

input,
textarea {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-appearance: none;
  border-radius: 0;
}

.form-input,
.form-textarea {
  padding: 8px 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  border-radius: 0;
  transition-property: color, border-color;
  transition-duration: 0.3s;
}

.form-input:focus,
.form-textarea:focus {
  border-color: #D2B677;
}

.form-input:focus::placeholder,
.form-textarea:focus::placeholder {
  color: white;
}

.form-input::placeholder,
.form-textarea::placeholder {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: white;
  transition: color 0.3s;
}

.form-input:-webkit-autofill,
.form-textarea:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: white;
}

.form-input.invalid,
.form-textarea.invalid {
  border-color: red;
}

.form-textarea {
  display: block;
  resize: none;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

.swiper-pagination-bullet {
  position: relative;
  background-color: white;
  opacity: 0.25;
  transition: opacity 0.3s;
}

.swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 0.3s;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination-bullet-active::after {
  border-color: white;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 24px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  left: 40px;
  right: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 16px 0;
}

.page-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (min-width: 1024px) {
  .page-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.page-header__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .page-header__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .page-header__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .page-header__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .page-header__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .page-header__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .page-header__container {
    width: calc(100% - 40px);
  }
}

.page-header__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.page-header__row > * {
  flex-basis: 100%;
}

.page-header__logo {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

@media (min-width: 1024px) {
  .page-header__logo {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.page-header__logo-wrap {
  display: block;
  height: 128px;
}

@media (min-width: 1024px) {
  .page-header__logo-wrap {
    position: absolute;
    top: 0;
    left: 12px;
  }
}

@media (min-width: 1280px) {
  .page-header__logo-wrap {
    height: 192px;
  }
}

@media (min-width: 1700px) {
  .page-header__logo-wrap {
    height: 240px;
  }
}

.page-header__logo-img {
  height: 100%;
  vertical-align: top;
}

.page-header__service {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
}

@media (min-width: 1024px) {
  .page-header__service {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 1024px) {
  .page-header__service {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.page-header__btn-wrap {
  display: none;
}

@media (min-width: 1024px) {
  .page-header__btn-wrap {
    display: block;
    margin-right: 64px;
  }
}

@media (min-width: 1024px) {
  .page-header__link-wrap {
    margin-right: 64px;
  }
}

.page-header__link {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.5);
}

.page-header__menu-toggle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  font-size: 0;
}

@media (min-width: 1024px) {
  .page-header__menu-toggle {
    margin-bottom: 0;
  }
}

.page-header__menu-label {
  margin-right: 24px;
  font-size: 18px;
  line-height: 25px;
  color: #D2B677;
}

@media (min-width: 1024px) {
  .page-header__menu-label {
    z-index: 1;
  }
}

.page-footer {
  display: none;
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 1;
  width: 100%;
}

@media (min-width: 1024px) {
  .page-footer {
    display: block;
  }
}

.page-footer__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .page-footer__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .page-footer__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .page-footer__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .page-footer__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .page-footer__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .page-footer__container {
    width: calc(100% - 40px);
  }
}

.page-footer__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.page-footer__row > * {
  flex-basis: 100%;
}

.page-footer__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.page-footer__copyright {
  font-size: 10px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 1440px) {
  .page-footer__copyright {
    font-size: 12px;
  }
}

.link--theme_white {
  color: white;
}

.link--theme_white:hover {
  color: #D2B677;
}

.link--theme_white:active {
  color: #D2B677;
}

.link--theme_transparent {
  color: rgba(255, 255, 255, 0.5);
}

.link--theme_transparent:hover {
  color: white;
}

.link--theme_transparent:active {
  color: white;
}

.link--theme_bordered {
  color: #D2B677;
  border-bottom: 2px solid #D2B677;
}

.link--theme_bordered:hover {
  color: #D2B677;
  border-color: transparent;
}

.link--theme_bordered:active {
  color: #D2B677;
}

.section {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .section {
    height: 100vh;
  }
}

.section__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .section__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .section__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .section__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .section__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .section__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .section__container {
    width: calc(100% - 40px);
  }
}

.section__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.section__row > * {
  flex-basis: 100%;
}

.section__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.main-title {
  color: #D2B677;
}

.main-subtitle {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 27px;
  text-transform: none;
  color: #D2B677;
}

@media (min-width: 768px) {
  .main-subtitle {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 34px;
  }
}

@media (min-width: 1440px) {
  .main-subtitle {
    font-size: 36px;
    line-height: 43px;
  }
}

.blockquote {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  margin-top: 0;
  margin-bottom: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
}

@media (min-width: 768px) {
  .blockquote {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
  }
}

.blockquote p {
  max-width: 60ch;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .blockquote p {
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
  }
}

h1,
h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  h1,
  h2 {
    margin-bottom: 24px;
    font-size: 44px;
    line-height: 53px;
  }
}

@media (min-width: 1440px) {
  h1,
  h2 {
    font-size: 54px;
    line-height: 65px;
  }
}

@media (min-width: 1440px) {
  h1 {
    margin-bottom: 8px;
    font-size: 72px;
    line-height: 86px;
    color: #D2B677;
  }
}

h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Roboto", "Ubuntu", "Droid Sans", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 768px) {
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 27px;
  }
}

@media (min-width: 1440px) {
  h3 {
    margin-bottom: 32px;
  }
}

p {
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  p {
    text-align: justify;
    font-size: 18px;
    line-height: 28px;
  }
}

.main-slider {
  width: 100%;
  height: 100%;
}

.main-slider__item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: none !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 152px;
  padding-bottom: 72px;
  box-sizing: border-box;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .main-slider__item {
    height: 100%;
    justify-content: center;
    padding: 0;
  }
}

.main-slider__bg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.main-slider__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.main-slider__img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.1;
}

.main-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';
}

.main-slider__header {
  text-align: center;
}

.main-slider__content {
  z-index: 1;
  width: 100%;
  padding-top: 24px;
}

@media (min-width: 768px) {
  .main-slider__content {
    padding-bottom: 24px;
  }
}

.main-slider__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .main-slider__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .main-slider__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .main-slider__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .main-slider__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .main-slider__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .main-slider__container {
    width: calc(100% - 40px);
  }
}

.main-slider__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.main-slider__row > * {
  flex-basis: 100%;
}

.main-slider__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 1024px) {
  .main-slider__col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 1024px) {
  .main-slider__col {
    margin-left: 8.33333%;
  }
}

@media (min-width: 1280px) {
  .main-slider__col {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 1280px) {
  .main-slider__col {
    margin-left: 16.66667%;
  }
}

@keyframes animateBg {
  from {
    transform: scale(1, 1) translateZ(1px);
  }
  to {
    transform: scale(1.45, 1.45) translateZ(1px);
  }
}

.page-nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  background-color: #621c22;
}

.page-nav__wrapper {
  height: 100%;
}

@media (min-width: 1024px) {
  .page-nav__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

.page-nav__inner {
  padding-top: 56px;
  padding-bottom: 56px;
}

.page-nav__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .page-nav__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .page-nav__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .page-nav__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .page-nav__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .page-nav__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .page-nav__container {
    width: calc(100% - 40px);
  }
}

.page-nav__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.page-nav__row > * {
  flex-basis: 100%;
}

.page-nav__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.page-nav__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.page-nav__item {
  position: relative;
  width: 100%;
  padding: 8px 0;
}

.page-nav__item:first-child {
  margin-bottom: 24px;
}

.page-nav__item:first-child::after {
  content: none;
}

.page-nav__item:last-child {
  margin-top: 24px;
}

.page-nav__item:last-child::after {
  content: none;
}

.page-nav__item:nth-last-child(2)::after {
  content: none;
}

@media (min-width: 1280px) {
  .page-nav__item {
    padding: 12px 0;
  }
}

.page-nav__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.25);
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  .page-nav__item::after {
    width: 50%;
  }
}

.page-nav__link {
  font-size: 18px;
  line-height: 25px;
  color: white;
}

@media (min-width: 1280px) {
  .page-nav__link {
    font-size: 24px;
    line-height: 32px;
  }
}

.page-nav__link--active {
  color: #D2B677;
}

.page-nav__btn-wrap {
  width: 100%;
  padding-top: 32px;
  text-align: center;
}

@media (min-width: 1024px) {
  .page-nav__btn-wrap {
    display: none;
  }
}

.contacts__text p {
  font-size: 20px;
  line-height: 27px;
}

@media (min-width: 1024px) {
  .contacts__text p {
    font-size: 32px;
    line-height: 38px;
  }
}

.contacts__phone-wrap + .contacts__phone-wrap {
  padding-top: 8px;
}

@media (min-width: 1024px) {
  .contacts__phone-wrap + .contacts__phone-wrap {
    padding-top: 16px;
  }
}

.contacts__phone {
  font-size: 20px;
  line-height: 27px;
}

@media (min-width: 1024px) {
  .contacts__phone {
    font-size: 32px;
    line-height: 38px;
  }
}

.contacts__email {
  user-select: none;
}

@media (min-width: 1024px) {
  .contacts__email {
    display: none;
  }
}

.contacts__email--desktop {
  display: none;
}

@media (min-width: 1024px) {
  .contacts__email--desktop {
    display: block;
  }
}

.contacts__link-wrap {
  margin-bottom: 4px;
}

.contacts__link-wrap:last-child {
  margin-bottom: 0;
}

.contacts__links {
  padding-top: 32px;
}

.contacts__link {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .contacts__link {
    font-size: 20px;
    line-height: 27px;
  }
}

.contacts__map {
  padding-top: 32px;
}

.fancybox-slide--html {
  padding: 0;
}

.fancybox-bg {
  background: #621c22;
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.modal {
  display: none;
  width: 100%;
  height: 100%;
  padding: 48px 0;
  background-color: transparent;
}

@media (min-width: 768px) {
  .modal {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media (min-width: 1280px) {
  .modal__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
}

.modal__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 0) {
  .modal__container {
    width: calc(100% - 16px);
    /*		@if ($sm-grid-gutter != $xs-grid-gutter) {
					padding-left: ($sm-grid-gutter / 2);
					padding-right: ($sm-grid-gutter / 2);
				}*/
  }
}

@media (min-width: 768px) {
  .modal__container {
    width: calc(100% - 24px);
  }
}

@media (min-width: 1024px) {
  .modal__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .modal__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1440px) {
  .modal__container {
    width: calc(100% - 40px);
  }
}

@media (min-width: 1700px) {
  .modal__container {
    width: calc(100% - 40px);
  }
}

.modal__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.modal__row > * {
  flex-basis: 100%;
}

.modal__col {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  .modal__col {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 768px) {
  .modal__col {
    margin-left: 8.33333%;
  }
}

@media (min-width: 1024px) {
  .modal__col {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 1024px) {
  .modal__col {
    margin-left: 25%;
  }
}

.modal__title {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 768px) {
  .modal__title {
    margin-bottom: 48px;
    font-size: 48px;
    line-height: 60px;
  }
}

.modal__body {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media (min-width: 768px) {
  .modal__close {
    top: 24px;
    right: 40px;
  }
}

.modal--map .modal__inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.modal--map .modal__body {
  max-width: none;
}

.map {
  width: 100%;
  height: 380px;
}

@media (min-width: 768px) {
  .map {
    height: 640px;
  }
}

.contact-form__col {
  margin-bottom: 24px;
}

.contact-form__col:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .contact-form__col {
    margin-bottom: 32px;
  }
}

.contact-form__input, .contact-form__textarea {
  width: 100%;
}

.contact-form__submit {
  width: 100%;
  text-align: center;
}

.contact-form__btn {
  width: 100%;
}

.contact-form__agreement :last-child {
  margin-bottom: 0;
}

.contact-form-success {
  display: none;
  text-align: center;
}

@media (min-width: 768px) {
  .contact-form-success p {
    font-size: 24px;
    line-height: 32px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #641E24;
}

.loader__logo {
  animation-name: logo-grow;
  animation-duration: 1.2s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.loader__logo-img * {
  fill: white;
}

@keyframes line-grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes logo-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}
