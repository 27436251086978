.link {

	&--theme_white {
		color: $white;

		&:hover {
			color: $color-secondary;
		}

		&:active {
			color: $color-secondary;
		}
	}

	&--theme_transparent {
		color: rgba($white, 0.5);

		&:hover {
			color: $white;
		}

		&:active {
			color: $white;
		}
	}

	&--theme_bordered {
		color: $color-secondary;
		border-bottom: 2px solid $color-secondary;

		&:hover {
			color: $color-secondary;
			border-color: transparent;
		}

		&:active {
			color: $color-secondary;
		}
	}
}
