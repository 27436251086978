.page-nav {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	-webkit-overflow-scrolling: auto;
	background-color: $body-bg;

	&__wrapper {
		height: 100%;

		@media (min-width: $screen-md) {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
		}
	}

	&__inner {

		padding-top: 56px;
		padding-bottom: 56px;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	&__item {
		position: relative;
		width: 100%;
		padding: 8px 0;

		&:first-child {
			margin-bottom: 24px;

			&::after {
				content: none;
			}
		}

		&:last-child {
			margin-top: 24px;

			&::after {
				content: none;
			}
		}

		&:nth-last-child(2) {

			&::after {
				content: none;
			}
		}

		@media (min-width: $screen-lg) {
			padding: 12px 0;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 100%;
			height: 1px;
			background-color: rgba($white, 0.25);
			transform: translateX(-50%);

			@media (min-width: $screen-md) {
				width: 50%;
			}
		}
	}

	&__link {
		font-size: 18px;
		line-height: 25px;
		color: $white;

		@media (min-width: $screen-lg) {
			font-size: 24px;
			line-height: 32px;
		}

		&--active {
			color: $color-secondary;
		}
	}

	&__btn-wrap {
		width: 100%;
		padding-top: 32px;
		text-align: center;

		@media (min-width: $screen-md) {
			display: none;
		}
	}
}
