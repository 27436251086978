.section {
	width: 100%;
	overflow: hidden;

	@media (min-width: $screen-md) {
		height: 100vh;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}
}
