.contacts {
	$self: &;

	&__text {

		p {
			font-size: 20px;
			line-height: 27px;

			@media (min-width: $screen-md) {
				font-size: 32px;
				line-height: 38px;
			}
		}
	}

	&__phone-wrap {

		+ #{$self}__phone-wrap {
			padding-top: 8px;

			@media (min-width: $screen-md) {
				padding-top: 16px;
			}
		}
	}

	&__phone {
		font-size: 20px;
		line-height: 27px;

		@media (min-width: $screen-md) {
			font-size: 32px;
			line-height: 38px;
		}
	}

	&__email {
		user-select: none;

		@media (min-width: $screen-md) {
			display: none;
		}

		&--desktop {
			display: none;

			@media (min-width: $screen-md) {
				display: block;
			}
		}
	}

	&__link-wrap {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__links {
		padding-top: 32px;
	}

	&__link {
		font-size: 18px;
		line-height: 24px;

		@media (min-width: $screen-md) {
			font-size: 20px;
			line-height: 27px;
		}
	}

	&__map {
		padding-top: 32px;
	}
}
