fieldset {
	display: block;
	margin: 0;
	padding: 0;
	border: none;
}

input,
textarea {
	font-family: $font-family;
	-webkit-appearance: none;
	border-radius: 0;
}

.form-input,
.form-textarea {
	padding: 8px 0;
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
	color: $text-color;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid $white;
	border-radius: 0;
	transition-property: color, border-color;
	transition-duration: $transition-time;

	&:focus {
		border-color: $color-secondary;

		&::placeholder {
			color: $white;
		}
	}

	&::placeholder {
		font-weight: 400;
		font-size: 24px;
		line-height: 36px;
		color: $white;
		transition: color $transition-time;
	}

	&:-webkit-autofill {
		-webkit-animation-name: autofill;
		-webkit-animation-fill-mode: both;
		-webkit-text-fill-color: $text-color;
	}

	&.invalid {
		border-color: red;
	}
}

.form-textarea {
	display: block;
	resize: none;
}

// FIXES

@-webkit-keyframes autofill {

	to {
		background: transparent;
	}
}
