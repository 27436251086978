.fancybox-slide--html {
	padding: 0;
}

.fancybox-bg {
	background: $color-primary;
}

.fancybox-is-open .fancybox-bg {
	opacity: 1;
}

.modal {
	$self: &;
	display: none;
	width: 100%;
	height: 100%;
	padding: 48px 0;
	background-color: transparent;

	@media (min-width: $screen-sm) {
		padding-top: 56px;
		padding-bottom: 56px;
	}

	&__inner {

		@media (min-width: $screen-lg) {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: flex-start;
			height: 100%;
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
		@include col(sm, 10, 12);
		@include col-offset(sm, 1, 12);
		@include col(md, 6, 12);
		@include col-offset(md, 3, 12);
	}

	&__title {
		margin-top: 0;
		margin-bottom: 24px;
		font-weight: 400;
		font-size: 28px;
		line-height: 34px;
		text-transform: uppercase;
		text-align: center;

		@media (min-width: $screen-sm) {
			margin-bottom: 48px;
			font-size: 48px;
			line-height: 60px;
		}
	}

	&__body {
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
	}

	&__close {
		position: absolute;
		top: 16px;
		right: 16px;

		@media (min-width: $screen-sm) {
			top: 24px;
			right: 40px;
		}
	}

	// Modifiers

	&--map {

		#{$self}__inner {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: flex-start;
			height: 100%;
		}

		#{$self}__body {
			max-width: none;
		}

	}
}

.map {
	width: 100%;
	height: 380px;

	@media (min-width: $screen-sm) {
		height: 640px;
	}
}
