.loader {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background-color: #641E24;

	&__logo {
		animation-name: logo-grow;
		animation-duration: 1.2s;
		animation-delay: 0s;
		animation-timing-function: ease;
		animation-fill-mode: both;
	}

	&__logo-img * {
		fill: $white;
	}
}

@keyframes line-grow {

	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

@keyframes logo-grow {

	0% {
		opacity: 0;
		transform: scale(1);
	}

	100% {
		opacity: 1;
		transform: scale(1.2);
	}
}

